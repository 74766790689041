<template>
  <AppHeaderDropdown right>
    <template slot="header">
      <div class="vertical-center">
        <h1>
          <div class="flag-wrapper">
            <span :class="flag"></span>
          </div>
        </h1>
      </div>
    </template>
    <template slot="dropdown">
      <b-dropdown-header tag="div" class="text-center">
        <strong>{{ $t("message.changeLanguage") }}</strong>
      </b-dropdown-header>
      <b-dropdown-item v-on:click="changeLanguage(0)">
        <i class="flag-icon flag-icon-th" /> ภาษาไทย
      </b-dropdown-item>
      <b-dropdown-item v-on:click="changeLanguage(1)">
        <i class="flag-icon flag-icon-gb" /> English
      </b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from "@coreui/vue";
export default {
  name: "DefaultHeaderDropdownAccnt",
  components: {
    AppHeaderDropdown,
  },
  data: () => {
    return {
      user: window.localStorage.getItem("userName"),
      lang: window.localStorage.getItem("language"),
      flag: `flag-icon flag-icon-${window.localStorage.getItem("language")}`,
    };
  },
  methods: {
    changeLanguage(index) {
      if (index === 0) {
        this.$i18n.locale = "th";
        this.flag = `flag-icon flag-icon-th`;
        window.localStorage.setItem("language", "th");
      } else if (index === 1) {
        this.$i18n.locale = "gb";
        this.flag = `flag-icon flag-icon-gb`;
        window.localStorage.setItem("language", "gb");
      }
      this.$store.state.nav = this.$t("message.nav");
      // window.location.reload()
      // console.log('language', window.localStorage.getItem("language"))
    },
  },
  mounted() {
    if (localStorage.getItem("isLogin")) {
      this.flag = `flag-icon flag-icon-${this.lang}`;
    } else {
      this.$router.push("/login");
    }
  },
};
</script>

<style>
.vertical-center {
  margin: 00;
  position: absolute;
  top: 70%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>
