<template>
  <AppHeaderDropdown right>
    <template slot="header">
      <img src="img/avatars/user.jpg" class="img-avatar" />
      <span class="d-md-down-none"
        >{{ $t("message.hello") }} {{ userName }}</span
      >
      <span
        variant="success"
        class="d-md-down-none"
        v-if="users.credit.value < 100000"
      >
        credit : {{ users.credit.value }}
      </span>
      <span
        variant="success"
        class="d-md-down-none"
        v-if="users.credit.type === 'limit'"
      >
        sms : {{ users.credit.valueSMS }}
      </span>
    </template>
    <template slot="dropdown">
      <b-dropdown-header tag="div" class="text-center">
        <strong>Account</strong>
      </b-dropdown-header>

      <!--
      <b-dropdown-item><i class="fa fa-bell-o" /> Updates
        <b-badge variant="info">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="fa fa-envelope-o" /> Messages
        <b-badge variant="success">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="fa fa-tasks" /> Tasks
        <b-badge variant="danger">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="fa fa-comments" /> Comments
        <b-badge variant="warning">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-header
        tag="div"
        class="text-center">
        <strong>Settings</strong>
      </b-dropdown-header>
      <b-dropdown-item><i class="fa fa-user" /> Profile</b-dropdown-item>
      <b-dropdown-item><i class="fa fa-wrench" /> Settings</b-dropdown-item>
      <b-dropdown-item><i class="fa fa-usd" /> Payments
        <b-badge variant="secondary">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="fa fa-file" /> Projects
        <b-badge variant="primary">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item><i class="fa fa-shield" /> Lock Account</b-dropdown-item>
      -->

      <b-dropdown-item v-on:click="logout()">
        <i class="fa fa-lock" />
        {{ $t("message.logout") }}
      </b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from "@coreui/vue";
export default {
  name: "DefaultHeaderDropdownAccnt",
  components: {
    AppHeaderDropdown,
  },
  data() {
    return {
      userName: window.localStorage.getItem("userName"),
      users: this.$store.state.user
        ? this.$store.state.user
        : JSON.parse(window.localStorage.getItem("users")),
    };
  },
  methods: {
    logout() {
      window.localStorage.clear();
      window.location.reload();
      this.$router.push("/login");
    },
  },
  mounted() {
    if (localStorage.getItem("isLogin")) {
    } else {
      this.$router.push("/login");
    }
  },
};
</script>
